'use client';

import { useEffect } from 'react';

import { initFullStory } from '@/utils/fullstory-utils';

export function FullStory() {
  useEffect(() => {
    initFullStory();
  }, []);

  return null;
}
