'use client';

import { sendGTMEvent } from '@next/third-parties/google';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { useCookieConsent } from '@/hooks/useCookieConsent';
import { usePathname } from '@/i18n/navigation';

export const GTM_BY_PATHNAME: Record<
  string,
  { pageType: string; options?: boolean }
> = {
  '/locations/': {
    pageType: 'location',
    options: true,
  },
  '/meeting-rooms/': {
    pageType: 'meeting-room',
    options: true,
  },
  '/locations': {
    pageType: 'location-search',
  },
  '/meeting-rooms': {
    pageType: 'location-search',
  },
  '/m/': {
    pageType: 'market',
  },
  '/': {
    pageType: 'homepage',
  },
};

export function NavigationEvents() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { canTrack, isDataLayerLoaded } = useCookieConsent();

  const pathnameKey =
    Object.keys(GTM_BY_PATHNAME).find((key) => pathname.startsWith(key)) ??
    pathname;

  const { pageType, options: hasOptionsToSend } =
    GTM_BY_PATHNAME[pathnameKey] ?? {};

  useEffect(() => {
    if (!isDataLayerLoaded || !canTrack) return;

    sendGTMEvent({ cookieConsent: canTrack });
  }, [canTrack, isDataLayerLoaded]);

  useEffect(() => {
    if (hasOptionsToSend || !isDataLayerLoaded || !canTrack) return;

    sendGTMEvent({
      event: 'pageview',
      url: pathname,
      pageType: pageType ?? pathname.split('/')[1],
    });
  }, [pathname, searchParams, isDataLayerLoaded, canTrack]);

  return null;
}
