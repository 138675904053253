'use client';

import { useEffect } from 'react';

import { usePathname } from '@/i18n/navigation';

export function Intellimize() {
  const pathname = usePathname();

  const handleOnRouteChangeComplete = () => {
    if (window.intellimize?.activate) {
      window.intellimize.activate();
    }
  };

  useEffect(() => {
    handleOnRouteChangeComplete();
  }, [pathname]);

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html:
            '(function(e,t,p){var n=document.documentElement,s={p:[],r:[]},u={p:s.p,r:s.r,push:function(e){s.p.push(e)},ready:function(e){s.r.push(e)}};e.intellimize=u,n.className+=" "+p,setTimeout(function(){n.className=n.className.replace(RegExp(" ?"+p),"")},t)})(window, 4000, \'anti-flicker\')',
        }}
      />
      <link
        rel="preload"
        href="https://cdn.intellimize.co/snippet/117515498.js"
        as="script"
      ></link>

      <script
        src="https://cdn.intellimize.co/snippet/117515498.js"
        async
        onError={() => {
          document.documentElement.className =
            document.documentElement.className.replace(
              RegExp(' ?anti-flicker'),
              ''
            );
        }}
      ></script>

      <link rel="preconnect" href="https://api.intellimize.co" crossOrigin="" />
      <link rel="preconnect" href="https://117515498.intellimizeio.com" />
      <link rel="preconnect" href="https://log.intellimize.co" crossOrigin="" />
    </>
  );
}
