'use client';

import Script from 'next/script';

export function CookieConsent() {
  return (
    <Script
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid="fe5720b8-3156-4405-a98d-f39b03dadeb1"
      data-blockingmode="auto"
      type="text/javascript"
    />
  );
}
