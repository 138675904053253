import { FullStory, init } from '@fullstory/browser';

const ORG_ID = 'o-22Q8VT-na1';

export function initFullStory() {
  init({ orgId: ORG_ID, startCaptureManually: true });
}
export function startFSRecording() {
  FullStory('start');
}

export function stopFSRecording() {
  FullStory('shutdown');
}
