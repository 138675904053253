'use client';

import { GoogleTagManager } from '@next/third-parties/google';
import Script from 'next/script';

import { useCookieConsent } from '@/hooks/useCookieConsent';

const GTM_ID = 'GTM-WGNZXNJ';

export function TrackingScripts() {
  const { canTrack } = useCookieConsent();

  if (!canTrack) {
    return null;
  }

  return (
    <>
      <Script
        id="hs-script-loader"
        src="//js.hs-scripts.com/44474178.js"
        strategy="lazyOnload"
        async
        defer
      />
      <GoogleTagManager gtmId={GTM_ID} />
    </>
  );
}
