'use client';

import { useEffect, useState } from 'react';

import * as gtm from '@/utils/gtm';

export function useCookieConsent() {
  const [canTrack, setCanTrack] = useState(false);
  const isDataLayerLoaded = gtm.isDataLayerLoaded();

  useEffect(() => {
    const onConsentAccept = () => {
      setCanTrack(true);
    };

    window.addEventListener('CookiebotOnAccept', onConsentAccept);
    return () => {
      window.removeEventListener('CookiebotOnAccept', onConsentAccept);
    };
  }, []);

  return { canTrack, isDataLayerLoaded };
}
